<template>
    <div class="pa-0 ma-0">
        <!--Parte visible del componente-->
        <tr>
            <td style="width:80px !important; padding-right:4px !important;">
                <div >
                    <v-checkbox :disabled="disabledCheck" class="mb-5" primary hide-details :value="item.empleado_id" v-model="seleccionado" @click="selectEmployee(item.empleado_id)"></v-checkbox>
                </div>
            </td> 
            <td style="padding-left:16px !important; min-width:300px!important;">
                <div>
                    <v-list class="py-0 my-0" style="background:transparent;">
                        <v-list-item class="py-0 my-0 px-0">
                            <v-list-item-avatar class="py-0 my-0">
                                <v-img class="py-0 my-0" :src="urlImg+item.foto"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content class="ml-0 py-0 my-0">
                                <v-list-item-title class="tblPrincipal text-wrap">{{ item.nombre_completo }}</v-list-item-title>
                                <v-list-item-title class="tblSecundario text-mute">{{/* rol=="root"?item.empleado.cliente.nombre:rol=="admin"?item.empleado.empresa.nombre:item.empleado.sucursal.nombre*/}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
            </td>
            <td style="min-width:300px!important;">
                <div v-if="item.contratos[0]">
                    {{item.contratos[0].nombre}}
                </div>
            </td>
            <td style="min-width:300px!important;">
                <div v-if="item.contratos[0]">
                    {{item.contratos[0].vigencia?item.contratos[0].vigencia:'Indeterminada'}}
                </div>
            </td>
            <td style="min-width:300px!important;">
                <div v-if="item.contratos[0]">
                    <p v-if="item.contratos[0].clave_estatus == 'listo'" class="estatus-listo mb-0 text-center">{{item.contratos[0].estatus}}</p> 
                    <v-tooltip bottom >
                        <template v-slot:activator="{ on }">
                            <p v-on="on" v-if="item.contratos[0].clave_estatus == 'incompleto'" class="estatus-incompleto mb-0 text-center">{{item.contratos[0].estatus}}</p>
                        </template>
                        <span>{{item.contratos[0].observaciones}}</span>
                    </v-tooltip>
                    <p v-if="item.contratos[0].clave_estatus == 'firmado'" class="estatus-firmado mb-0 text-center">{{item.contratos[0].estatus}}</p>
                    <p v-if="item.contratos[0].clave_estatus == 'vencido'" class="estatus-vencido mb-0 text-center">{{item.contratos[0].estatus}}</p>
                    <p v-if="item.contratos[0].clave_estatus == 'cancelado'" class="estatus-cancelado mb-0 text-center">{{item.contratos[0].estatus}}</p>
                    <p v-if="item.contratos[0].clave_estatus == 'por_vencer'" class="estatus-por_vencer mb-0 text-center">{{item.contratos[0].estatus}}</p>
                    <p v-if="item.contratos[0].clave_estatus == 'renovado'" class="estatus-renovado mb-0 text-center">{{item.contratos[0].estatus}}</p>
                </div>
            </td>
            <td style="padding-left:8px; padding-right:8px;">
                <div class="tblOpciones" v-if="item.contratos[0]">
                    <template v-if="(tipoDoc(item.contratos[0].url_archivo) && !item.contratos[0].contiene_plantilla) || item.contratos[0].url_archivo">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                            <v-btn v-if="item.contratos[0].previsualizar" v-on="on" class="botonHover" icon fab dark small @click="abrirModalPDF(item.contratos[0])">
                                <v-icon 
                                    v-text="'$visibility_outline'"
                                    class="iconoDelgadoBoton"
                                ></v-icon>
                            </v-btn>
                            </template>
                            <span>Vista previa</span>
                        </v-tooltip>
                    </template>
                    <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones"> 
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon 
                                fab
                                dark
                                small
                                class="elevation-0"
                            >
                                <template  v-for="(item,valor) in attrs" >
                                    <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                    <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                </template>
                            </v-btn>
                        </template>

                        <v-list>

                            <template v-if="item.baja">
                                <v-list-item
                                    v-if="item.contratos[0].clave_estatus != 'incompleto'"
                                    @click="descargar(item.contratos[0], item.nombre_completo)"
                                >
                                    <v-list-item-title>Descargar contrato</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="item.contratos[0].clave_estatus != 'incompleto'"
                                    @click="descargarConDocumentos(item.contratos[0], item.nombre_completo)"
                                >
                                    <v-list-item-title>Descargar contrato con documentos</v-list-item-title>
                                </v-list-item>
                            </template>

                            <template v-else>
                                <v-list-item
                                    v-if="
                                        item.contratos[0].clave_estatus == 'listo' ||
                                        item.contratos[0].clave_estatus == 'firmado' ||
                                        item.contratos[0].clave_estatus == 'vencido' ||
                                        item.contratos[0].clave_estatus == 'por_vencer'
                                    "
                                    @click="abrirModalRenovar(item.contratos[0])"
                                >
                                    <v-list-item-title>Renovar contrato</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        item.contratos[0].clave_estatus == 'listo' ||
                                        item.contratos[0].clave_estatus == 'incompleto' ||
                                        item.contratos[0].clave_estatus == 'firmado' ||
                                        item.contratos[0].clave_estatus == 'vencido' ||
                                        item.contratos[0].clave_estatus == 'por_vencer'
                                    "
                                    @click="regenerarContrato(item.contratos[0])"
                                >
                                    <v-list-item-title>Volver a generar</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        item.contratos[0].clave_estatus == 'listo' ||
                                        item.contratos[0].clave_estatus == 'firmado' ||
                                        item.contratos[0].clave_estatus == 'vencido' ||
                                        item.contratos[0].clave_estatus == 'cancelado' ||
                                        item.contratos[0].clave_estatus == 'por_vencer' ||
                                        item.contratos[0].clave_estatus == 'renovado'
                                    "
                                    @click="descargar(item.contratos[0], item.nombre_completo)"
                                >
                                    <v-list-item-title>Descargar contrato</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        item.contratos[0].clave_estatus == 'listo' ||
                                        item.contratos[0].clave_estatus == 'firmado' ||
                                        item.contratos[0].clave_estatus == 'vencido' ||
                                        item.contratos[0].clave_estatus == 'cancelado' ||
                                        item.contratos[0].clave_estatus == 'por_vencer' ||
                                        item.contratos[0].clave_estatus == 'renovado'
                                    "
                                    @click="descargarConDocumentos(item.contratos[0], item.nombre_completo)"
                                >
                                    <v-list-item-title>Descargar contrato con documentos</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        item.contratos[0].clave_estatus == 'listo' ||
                                        item.contratos[0].clave_estatus == 'firmado' ||
                                        item.contratos[0].clave_estatus == 'vencido' ||
                                        item.contratos[0].clave_estatus == 'por_vencer' ||
                                        (item.contratos[0].clave_estatus == 'renovado' && item.contratos[0].url_archivo == null)
                                    "
                                    @click="abrirModalCargaDocumento(item,item.contratos[0].id)"
                                >
                                    <v-list-item-title v-if="item.contratos[0].clave_estatus == 'firmado'">Actualizar contrato firmado</v-list-item-title>
                                    <v-list-item-title v-else>Subir contrato firmado</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="item.contratos[0].clave_estatus == 'cancelado' "
                                    @click="eliminar(item.contratos[0].id)"
                                >
                                    <v-list-item-title>Eliminar contrato</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="item.contratos[0].url_archivo"
                                    @click="eliminarDocumento(item.contratos[0].id, item.contratos[0].url_archivo)"
                                >
                                    <v-list-item-title>Eliminar documento contrato</v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </div>
            </td>
            <td style="min-width:70px!important; max-width:81px!important; width:80px; padding-left:8px;">
                <div class="tblOpciones" v-if="contratos.length > 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon fab dark small @click="isOpen ? isOpen = false:isOpen = true">
                            <v-icon class="tamIconoBoton iconoDelgadoBoton">{{isOpen ? 'keyboard_arrow_up':'keyboard_arrow_down'}}</v-icon>
                        </v-btn>
                        </template>
                        <span>Desplegar</span>
                    </v-tooltip>
                </div>
            </td>
        </tr>
        <!--Parte desplegable del componente-->
        <template v-if="isOpen">
            <tr :key="contrato.id" v-for="contrato in contratos"  class="td-short">
                <td style="width:70px !important; padding-left:40px; padding-right:40px">
                </td>
                <td>
                </td>
                <td>
                    <div>
                        {{contrato.nombre}}
                    </div>
                </td>
                <td>
                    <div>
                        {{contrato.vigencia?contrato.vigencia:'Indeterminada'}}
                    </div>
                </td>
                <td>
                    <div>
                        
                        <p v-if="contrato.clave_estatus == 'listo'" class="estatus-listo mb-0 text-center">{{contrato.estatus}}</p>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <p v-on="on" v-if="contrato.clave_estatus == 'incompleto'" class="estatus-incompleto mb-0 text-center">{{contrato.estatus}}</p>
                            </template>
                            <span>{{contrato.observaciones}}</span>
                        </v-tooltip>
                        <p v-if="contrato.clave_estatus == 'firmado'" class="estatus-firmado mb-0 text-center">{{contrato.estatus}}</p>
                        <p v-if="contrato.clave_estatus == 'vencido'" class="estatus-vencido mb-0 text-center">{{contrato.estatus}}</p>
                        <p v-if="contrato.clave_estatus == 'cancelado'" class="estatus-cancelado mb-0 text-center">{{contrato.estatus}}</p>
                        <p v-if="contrato.clave_estatus == 'por_vencer'" class="estatus-por_vencer mb-0 text-center">{{contrato.estatus}}</p>
                        <p v-if="contrato.clave_estatus == 'renovado'" class="estatus-renovado mb-0 text-center">{{contrato.estatus}}</p>
                    </div>
                </td>
                <td style="padding-left:8px; padding-right:8px;">
                    <div class="tblOpciones">
                        <template v-if="(tipoDoc(contrato.url_archivo) && !contrato.contiene_plantilla) || contrato.url_archivo">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                <v-btn v-if="contrato.previsualizar" v-on="on" class="botonHover" icon fab dark small @click="abrirModalPDF(contrato)">
                                    <v-icon 
                                        v-text="'$visibility_outline'"
                                        class="iconoDelgadoBoton"
                                    ></v-icon>
                                </v-btn>
                                </template>
                                <span>Vista previa</span>
                            </v-tooltip>
                        </template>



                        <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones"> 
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon 
                                    fab
                                    dark
                                    small
                                    class="elevation-0"
                                >
                                    <template  v-for="(item,valor) in attrs" >
                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                    </template>
                                </v-btn>
                            </template>

                            <v-list>
                                <template v-if="item.baja">
                                    <v-list-item
                                        v-if="contrato.clave_estatus  != 'incompleto'"
                                        @click="descargar(contrato, item.nombre_completo)"
                                    >
                                        <v-list-item-title>Descargar contrato</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="contrato.clave_estatus  != 'incompleto'"
                                        @click="descargarConDocumentos(contrato, item.nombre_completo)"
                                    >
                                        <v-list-item-title>Descargar contrato con documentos</v-list-item-title>
                                    </v-list-item>
                                </template>
                                <template v-else>
                                    <v-list-item
                                        v-if="
                                            contrato.clave_estatus == 'listo' ||
                                            contrato.clave_estatus == 'incompleto' ||
                                            contrato.clave_estatus == 'firmado' ||
                                            contrato.clave_estatus == 'vencido' ||
                                            contrato.clave_estatus == 'por_vencer'
                                        "
                                        @click="regenerarContrato(contrato)"
                                    >
                                        <v-list-item-title>Volver a generar</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="
                                            contrato.clave_estatus == 'listo' ||
                                            contrato.clave_estatus == 'firmado' ||
                                            contrato.clave_estatus == 'vencido' ||
                                            contrato.clave_estatus == 'cancelado' ||
                                            contrato.clave_estatus == 'por_vencer' ||
                                            contrato.clave_estatus == 'renovado'
                                        "
                                        @click="descargar(contrato, item.nombre_completo)"
                                    >
                                        <v-list-item-title>Descargar contrato</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="
                                            contrato.clave_estatus == 'listo' ||
                                            contrato.clave_estatus == 'firmado' ||
                                            contrato.clave_estatus == 'vencido' ||
                                            contrato.clave_estatus == 'cancelado' ||
                                            contrato.clave_estatus == 'por_vencer' ||
                                            contrato.clave_estatus == 'renovado'
                                        "
                                        @click="descargarConDocumentos(contrato, item.nombre_completo)"
                                    >
                                        <v-list-item-title>Descargar contrato con documentos</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="
                                            contrato.clave_estatus == 'listo' ||
                                            contrato.clave_estatus == 'firmado' ||
                                            contrato.clave_estatus == 'vencido' ||
                                            contrato.clave_estatus == 'por_vencer' ||
                                            (contrato.clave_estatus == 'renovado' && contrato.url_archivo == null)
                                        "
                                        @click="abrirModalCargaDocumento(item,contrato.id)"
                                    >
                                        <v-list-item-title v-if="contrato.clave_estatus == 'firmado'">Actualizar contrato firmado</v-list-item-title>
                                        <v-list-item-title v-else>Subir contrato firmado</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="contrato.clave_estatus == 'cancelado'"
                                        @click="eliminar(contrato.id)"
                                    >
                                        <v-list-item-title>Eliminar contrato</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="contrato.url_archivo"
                                        @click="eliminarDocumento(contrato.id, contrato.url_archivo)"
                                    >
                                        <v-list-item-title>Eliminar documento contrato</v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>
                    </div>
                </td>
                <td  style="min-width:70px!important; max-width:81px!important; width:80px; padding-left:8px;"></td>
            </tr>
        </template>
    </div>
</template>


<script>

//IMPORT

//PLUGINS
import GetUrlImg from "@/plugins/Urlimagen";
import Notify from '@/plugins/notify';

export default {
    name: 'expandable-item',

    //PORPS item Objeto contrato{}
    props: {
        item: {
            type: Object,
            required: true,
            default: ''
        },
        selected_general: {
            type: Array,
            required: true,
            default: ''
        },
        full: {
            type: Boolean,
            default: true
        },
        disabledCheck: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isOpen: false,
            urlImg : '',
            seleccionado:null,
            //select : this.selected_general
        }
    },
    watch: {
        selected_general(val){
            if(val.includes(this.item.empleado_id)){
                this.seleccionado = this.item.empleado_id
            }
            else{
                this.seleccionado = null
            }
        }
    },
    //COMPUTED

    /*
    Entrada:
    Salida:
    Descripcion: Las funciones de este tipo se tratan como variables 
                 reactivas y sirven para que la misma variable haga 
                 una funcion para formatar los datos y regresar un 
                 nuevo valor en vez de crear funciones noramles y 
                 tener que llamarlas. Estas apenas se asignen se ejecutaran 
                 y ellas mismas regrsaran el nuevo resultado.
    */
    computed: {
        /*
        Entrada: 
        Salida: contratos Array de Objetos contratos[{},{}]
        Descripcion: Regresa la lista de contratos hijos y padre de un 
                     empleado siendo el primer elemento el padre y los 
                     sobrantes el hijo ya que la vraiable todos los 
                     contratos estan en un mismo nivel.
        */
        contratos(){
            let contratos = JSON.parse(JSON.stringify(this.item.contratos));
            contratos.shift();
            return contratos;
        },
        /* select(){
            return this.selected_general
        } */
    },
    methods: {

        //FUNCIONES GENERALES

        /*
        Entrada: data Objeto contrato{}
        Salida: 
        Descripcion: Ejecuta la funcion abrirModalPDF() del lado del 
                     componente padre por medio de un $emit enviando 
                     los parametros que recibe.
        */
        selectEmployee(id){
            this.$emit("selectEmployee",id);
        },

        /*
        Entrada: data Objeto contrato{}
        Salida: 
        Descripcion: Ejecuta la funcion abrirModalPDF() del lado del 
                     componente padre por medio de un $emit enviando 
                     los parametros que recibe.
        */
        abrirModalPDF(data){
            this.$emit("abrirModalPDF",data);
        },

        /*
        Entrada: data Objeto contrato{}
        Salida: 
        Descripcion: Ejecuta la funcion descargar() del lado del 
                     componente padre por medio de un $emit enviando 
                     los parametros que recibe.
        */
        descargar(data, nombre_completo){
            this.$emit("descargar",{data:data,nombre_completo:nombre_completo});
        },

        descargarConDocumentos(data, nombre_completo){
            this.$emit("descargarConDocumentos",{data:data,nombre_completo:nombre_completo});
        },

        /*
        Entrada: data Objeto contrato{}
        Salida: 
        Descripcion: Ejecuta la funcion abrirModalRenovar del lado del 
                     componente padre por medio de un $emit enviando 
                     los parametros que recibe.
        */
        abrirModalRenovar(data){
            this.$emit("abrirModalRenovar",data);
        },

        /*
        Entrada: data Objeto contrato{}
        Salida: 
        Descripcion: Ejecuta la funcion regenerarContrato del lado del 
                     componente padre por medio de un $emit enviando 
                     los parametros que recibe.
        */
        regenerarContrato(data){
            this.$emit("regenerarContrato",data);
        },

        /*Entrada: id int contrato_id
        Salida: 
        Descripcion: Ejecuta la funcion eliminar() del lado del 
                     componente padre por medio de un $emit enviando 
                     los parametros que recibe.
        */
        eliminar(id){
            this.$emit("eliminar",id);
        },

        eliminarDocumento(id, url_archivo){
            if(url_archivo == null){
                Notify.ErrorToast("No hay archivo para eliminar.");
            }
            else{
                this.$emit("eliminarDocumento",id);
            }
        },

        /*
        Entrada: data Objeto contrato{} padre, id del contrato
        Salida: 
        Descripcion: Ejecuta la funcion abrirModalCargaDocumento del lado del 
                     componente padre por medio de un $emit enviando 
                     los parametros que recibe.
        */
        abrirModalCargaDocumento(data,id){
            this.$emit("abrirModalCargaDocumento",{data:data,id:id});
        },
        tipoDoc(url=null){
            if(url == null){
                return true;
            }
            else if(url.endsWith('.jpg') || url.endsWith('.pdf') || url.endsWith('.png') || url.endsWith('.jpeg')){
                return true;
            }
            else{
                return false;
            } 
        },
    },
    //MOUNTED se ejecutan instrucciones una vez se crea el componente;
    mounted () {
        /*
        Entrada:
        Salida:
        Descripcion: Ejectua la funcion getURL que trae la url base de las imagenes.
        */
        this.urlImg = GetUrlImg.getURL();
    },
}
</script>

<style scoped>
    /**
    * TRANSITION EFFECT
    */
    .slide-enter-active,
    .slide-leave-active {
        transition-duration: 0.8s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .slide-enter,
    .slide-leave-active {
    opacity: 0
    }

    /*STATUS*/
    .estatus-listo{
        border: solid 0.5px #ccd3e2 !important;
        color: #325687;
        padding:11px 0px;
        border-radius: 8px;
        width: 135px;
    }
    .estatus-incompleto{
        border: solid 0.5px #636997 !important;
        color: #636997;
        padding:11px 0px;
        border-radius: 8px;
        width: 100px;
    }
    .estatus-firmado{
        border: solid 0.5px #c5f7cd !important;
        color: #3CC13B;
        padding:11px 0px;
        border-radius: 8px;
        width: 80px;
    }
    .estatus-vencido{
        border: solid 0.5px #828282 !important;
        color: #828282;
        padding:11px 0px;
        border-radius: 8px;
        width: 91px;
    }
    .estatus-cancelado{
        border: solid 0.5px #ffc9cc !important;
        color: #F03738;
        padding:11px 0px;
        border-radius: 8px;
        width: 100px;
    }
    .estatus-por_vencer{
        border: solid 0.5px #E19117 !important;
        color: #E19117;
        padding:11px 0px;
        border-radius: 8px;
        width: 175px;
    }
    .estatus-renovado{
        border: solid 0.5px #2DB9D1 !important;
        color: #2DB9D1;
        padding:11px 0px;
        border-radius: 8px;
        width: 175px;
    }
    
    
    
    
    

    /*TABLE*/
    /*
    .filter-asc {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid white;
        margin-bottom: 1px;
    }
    .filter-desc {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid white;
        margin-top: 1px;
    } 
    .active-filter-asc {
        border-bottom: 5px solid #ccc;
    }
    .active-filter-desc {
        border-top: 5px solid #ccc;
    }
    */
    .filter {
        color: #96999a;
        cursor: pointer;
    }
    .inline-block {
        display: inline-block;
    }
    /*
    .table-header-sorting {
        cursor: pointer;
    }*/
    table {
        border-collapse: collapse;
        width: 100%;
        /* white-space: nowrap; */
        overflow-x: scroll;
        /* overflow-y: scroll; */
    }
    th{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        color: #828282;
        background-color: #f6f5f6;
        padding: 13px 45px 13px 45px;
    }
    th > div{
        max-width: 15rem;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }
    td{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        color: #1E2245;
        background-color: #fdfcfc;
        padding: 13px 45px 13px 45px;
        letter-spacing: -0.34px;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 15rem;
    }

    td > div{
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 15rem;
        margin-right: auto;
        margin-left: auto;
    }
    td > .loading{
        max-width: 100% !important;
    }
  
/*
    tr:nth-child(1n){

        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #EFF1FB;
    
    }
    
    tr:hover{background-color: #FCFCFC}
    
    th {
    
        background-color: #f3f4f6;
        color:#96999A;
        font-weight: 500;
    }
*/
    
    /**Fin tabla kardex */
    tbody {
      display:block;
      max-height:60vh;
      min-width: 1000px;
      background-color: #fdfcfc;
    }
    tr {
      display:table;
      table-layout:fixed;
      width: 100%;
    }
    table.ck-table.modal  tbody {
      display:block;
      max-height:60vh;
      min-width: 700px;
      background-color: #fdfcfc;
    }

    .td-short td{
        padding-top:1px !important;
        padding-bottom:1px !important;
        background: #f7f7f7;
    }

    tbody > div {
        vertical-align: middle !important;
    }

    .menuClassAcciones{
        border-radius: 10px!important;
    }
    
</style>